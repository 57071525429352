<template>
  <v-radio-group
    dense
    mandatory
    class="custom-radio-group"
    :class="customClass"
    id="id"
    v-model="radioinput"
    :row="row"
    :readonly="readonly"
    v-on:change="changeInput()"
    :hide-details="hideDetails"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="pa-1 rounded-sm custom-radio-input"
      :class="{
        active: item.value == radioinput,
      }"
    >
      <v-radio
        :color="item.color ? item.color : color"
        :disabled="disabled"
        :value="item.value"
      >
        <template #label>
          <img v-if="item.img" style="max-width: 25px" :src="item.img" alt="" />
          {{ item.label }}
        </template>
      </v-radio>
    </div>
  </v-radio-group>
</template>
<script>
export default {
  name: "radio-input",
  title: "Radio Input",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Boolean, Number, String],
      default: null,
    },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "mt-3 pt-0",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        this.radioinput = param;
      },
    },
  },
  data() {
    return {
      radioinput: null,
    };
  },
  methods: {
    changeInput() {
      this.$nextTick(() => {
        this.$emit("change", this.radioinput);
      });
    },
  },
  mounted() {
    this.radioinput = this.value;
  },
};
</script>
<style lang="scss">
.custom-radio-group .v-input--radio-group__input {
  grid-gap: 8px;
}
.custom-radio-input {
  border: 1px solid grey;
  flex-grow: 1;
  &.active {
    border-color: #00248a !important;
    background: #00248a14 !important;
  }
}
</style>
