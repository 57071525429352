<template>
  <Dialog :common-dialog="productDialog" :dialog-width="dialog_width">
    <template v-slot:title>
      <v-layout>
        <v-flex>
          <span class="text-capitalize"
            >Select <template v-if="productType == 'tools'"> Tool</template>
            <template v-else-if="productType == 'product'">
              Spare Part</template
            >
            <template v-else> {{ productType }}</template
            >s</span
          >
          <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon>
          Line Item
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button mr-2"
            depressed
            :disabled="vStateLoading"
            color="cyan white--text"
            v-on:click="save_line_item()"
            >Save</v-btn
          >
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            :disabled="vStateLoading"
            v-on:click="$emit('close', true)"
            >Close</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div>
        <div
          v-if="vLineItemAlpha && vLineItemAlpha.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        >
          <!--  <div
            style="position: relative"
            v-for="(alpha, index) in vLineItemAlpha"
            :key="`alphabet-${index}`"
            :class="{ 'alpha-exist': alpha.exist }"
            class="alph-listing custom-border-right"
          >
            <v-btn
              color="cyan white--text custom-bold-button"
              depressed
              tile
              :disabled="vStateLoading"
              :text="alphabet == alpha.value"
              v-on:click="filter_alpha(alpha.value)"
            >
              {{ alpha.label }}
            </v-btn>
            <v-icon v-if="alpha.exist" color="red">mdi-record</v-icon>
          </div> -->
        </div>
        <div
          v-if="vLineItemCategory && vLineItemCategory.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        >
          <div
            style="position: relative"
            v-for="(cat, index) in vLineItemCategory"
            :key="`category-${index}`"
            class="custom-border-right"
          >
            <v-btn
              color="cyan white--text custom-bold-button"
              depressed
              tile
              :disabled="vStateLoading"
              :text="category == cat.value"
              v-on:click="filter_cat(cat.value)"
            >
              {{ cat.label }}
            </v-btn>
          </div>
        </div>
        <div class="d-flex mb-2">
          <RadioInputCustom
            v-if="
              productType == 'equipment' &&
              moduleType == 10 &&
              deliveryType == 'outgoing' &&
              vCustomerType == 'customer-list'
            "
            v-model="eqp_filter"
            v-on:change="find_items()"
            row
            custom-class="mt-0 pt-0"
            color="red darken-4"
            :items="[
              {
                label: `A&G Equipments`,
                value: 'all',
                color: 'orange darken-4',
              },
              {
                label: `Customer Equipments`,
                value: 'customers',
                color: 'light-green darken-3',
              },
            ]"
          ></RadioInputCustom>
          <v-text-field
            v-model="search"
            class="line-item-header-text px-0 v-slot-m-none ml-2"
            placeholder="Search..."
            dense
            filled
            solo
            :disabled="vStateLoading"
            clearable
            flat
            color="cyan"
            style="max-width: 400px"
          />
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button ml-2"
            depressed
            :disabled="vStateLoading"
            v-on:click="find_items()"
            color="cyan white--text"
            >Search</v-btn
          >
        </div>
        <div>
          <div class="d-flex grey lighten-4">
            <div
              class="font-weight-600 py-2 text-center d-flex justify-center"
              style="width: 5%"
            >
              <v-checkbox
                v-on:change="update_all_line_item($event)"
                hide-details
                :disabled="vStateLoading"
                class="my-0 py-0 px-0 mx-0 dense-checkbox"
                color="cyan"
              />
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left text-capitalize"
              style="width: 60%"
            >
              <template v-if="productType == 'tools'"> Equipment</template>
              <template v-else-if="productType == 'product'">
                Spare Part</template
              >
              <template v-else>{{ productType }}</template>
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left"
              style="width: 17.5%"
            >
              Selling/Unit Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left"
              style="width: 17.5%"
            >
              Quantity
            </div>
          </div>
          <div style="overflow-y: scroll; max-height: calc(100vh - 360px)">
            <div v-if="vStateLoading" class="my-4 py-4">
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div>
            <div v-else-if="vLineItem && vLineItem.length">
              <div
                v-for="(row, index) in vLineItem"
                :key="index"
                class="d-flex my-2"
                :class="getDisabled(row) ? 'eqp-opacity' : ''"
              >
                <div
                  class="font-weight-600 text-center d-flex justify-center"
                  style="width: 5%"
                >
                  <v-checkbox
                    v-model="row.status"
                    :disabled="getDisabled(row)"
                    hide-details
                    class="my-0 py-0 px-0 mx-0 dense-checkbox"
                    color="cyan"
                  />
                </div>
                <div class="text-left" style="width: 60%">
                  <div class="d-flex">
                    <div>
                      <v-avatar size="80">
                        <img
                          v-if="row?.image?.url"
                          :src="row?.image?.url"
                          :alt="row?.image?.name"
                        />
                        <img v-else :src="$defaultImage" />
                      </v-avatar>
                      <div
                        v-if="row.has_property"
                        class="font-weight-600 d-flex align-center"
                      >
                        <template
                          v-if="
                            /* productType == 'equipment' &&  */ row &&
                            row.barcode
                          "
                        >
                          <v-chip
                            label
                            color="chip-custom-blue"
                            outlined
                            small
                            class="text-white p-3 mt-2"
                            style="font-weight: 700"
                            >{{ row.barcode }}</v-chip
                          >
                        </template>
                      </div>
                    </div>
                    <div style="width: 100%" class="ml-4">
                      <v-text-field
                        v-model="row.product"
                        class="line-item-header-text px-0"
                        :placeholder="productType"
                        dense
                        filled
                        solo
                        flat
                        color="cyan"
                        :disabled="getDisabled(row)"
                      />
                      <v-textarea
                        class="px-0"
                        v-model="row.description"
                        placeholder="Description"
                        dense
                        filled
                        solo
                        flat
                        rows="2"
                        auto-grow
                        color="cyan"
                        :disabled="getDisabled(row)"
                      />

                      <!--   <v-checkbox
                           v-if="row.type == 'product'"
                          inset
                          color="cyan"
                          label="As Equipment"
                          dense
                          class="pt-0 mt-2"
                          v-model="row.is_equipment" 
                          hide-details
                          :false-value="0"
                          :true-value="1"
                        > 
                      </v-checkbox>  -->
                      <div
                        v-if="row.has_property"
                        class="font-weight-600 d-flex align-center"
                      >
                        <!-- <template
                          v-if="
                            productType == 'equipment' && row && row.serial_no
                          "
                        >
                          <v-chip
                            label
                            color="chip-custom-blue"
                            outlined
                            class="text-white p-3 mr-2"
                            style="font-weight: 700; margin-top: -6px"
                            >{{ row.serial_no }}</v-chip
                          >
                        </template> -->
                        <!--  <template v-if="row?.property?.parent?.name">
                          <v-chip small>{{ row.property.parent.name }}</v-chip>
                          <v-icon class="px-1">mdi-chevron-right</v-icon>
                        </template>
                        <template v-if="row?.property?.child?.name">
                          <v-chip small style="padding: 12px !important">{{
                            row.property.child.name
                          }}</v-chip>
                          <v-icon class="px-1">mdi-chevron-right</v-icon>
                        </template>
                        <template v-if="row?.property?.type == 'child-child-'">
                          <v-chip small style="padding: 12px !important">
                            <div>
                              <div>
                                {{ row?.property?.property_name }}
                              </div>

                              <div>{{ row.property.name }}</div>
                            </div>
                          </v-chip>
                        </template>
                        <template v-if="row?.property?.type == 'child-'">
                          <v-chip small style="padding: 12px !important">
                            <div>
                              <div>
                                {{ row?.property?.property_name }}
                              </div>

                              <div>{{ row.property.name }}</div>
                            </div>
                          </v-chip>
                        </template>
                        <template v-if="row?.property?.type == 'parent-'">
                          <v-chip
                            small
                            style="padding: 12px !important"
                            v-if="row?.property?.property_name"
                          >
                            <div>
                              <div>
                                {{ row?.property?.property_name }}
                              </div>

                              <div>{{ row.property.name }}</div>
                            </div>
                          </v-chip>
                        </template> -->
                        <template
                          v-if="
                            (productType == 'equipment' ||
                              productType == 'tools') &&
                            row &&
                            row.has_warranty &&
                            row.has_warranty > 0
                          "
                        >
                          <v-chip
                            :color="getWarrantyStatusColor(row.warranty_status)"
                            small
                            text-color="white"
                          >
                            Warranty
                          </v-chip>
                        </template>
                        <template
                          v-if="
                            (productType == 'equipment' ||
                              productType == 'tools') &&
                            row &&
                            row.preventive_id &&
                            row.preventive_id > 0
                          "
                        >
                          <v-chip
                            :color="row?.is_consumed ? 'red' : 'lightgray'"
                            class="ml-2"
                            small
                            text-color=""
                            outlined
                            @click="openSchedule(row.preventive_id)"
                          >
                            {{ row.preventive_barcode }}
                          </v-chip>
                        </template>

                        <template
                          v-if="
                            productType == 'equipment' && row && row.location
                          "
                        >
                          <v-chip
                            color="lightgray"
                            class="ml-1 p-3"
                            small
                            text-color=""
                            outlined
                          >
                            <b class="font-weight-700">Location:</b>&nbsp;{{
                              row.location
                            }}
                          </v-chip>
                        </template>

                        <template
                          v-if="
                            productType == 'equipment' && row && row.serial_no
                          "
                        >
                          <v-chip
                            color="lightgray"
                            class="ml-1 p-3"
                            small
                            text-color=""
                            outlined
                          >
                            <b class="font-weight-700">Serial No.:</b>&nbsp;{{
                              row.serial_no
                            }}
                          </v-chip>
                        </template>
                        <template
                          v-if="
                            productType == 'equipment' && row && row?.is_sold
                          "
                        >
                          <v-chip
                            color="red"
                            class="ml-1 p-3"
                            small
                            text-color=""
                            outlined
                          >
                            SOLD
                          </v-chip>
                        </template>
                        <template
                          v-if="
                            productType == 'equipment' && row && row?.is_rental
                          "
                        >
                          <v-chip
                            color="red"
                            class="ml-1 p-3"
                            small
                            text-color=""
                            outlined
                          >
                            RENTED
                          </v-chip>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center" style="width: 17.5%">
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Selling/Unit Cost"
                    dense
                    filled
                    solo
                    type="number"
                    v-model="row.rate"
                    flat
                    :disabled="getDisabled(row)"
                    v-on:keypress="limitDecimal($event, row.rate)"
                    color="cyan"
                  />
                </div>
                <div class="text-center" style="width: 17.5%">
                  <v-text-field
                    :readonly="
                      productType == 'equipment' ||
                      productType == 'tools' ||
                      productType == 'service'
                    "
                    class="line-item-header-text px-0"
                    placeholder="Quantity"
                    dense
                    filled
                    type="number"
                    v-model="row.quantity"
                    :disabled="getDisabled(row)"
                    solo
                    flat
                    v-on:keypress="isNumber($event)"
                    color="cyan"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no item at the moment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <PMSchedule
        v-if="scheduleDialog"
        :parent-id="pmId"
        :dialogStatus="scheduleDialog"
        v-on:close:dialog="scheduleDialog = false"
      >
      </PMSchedule>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { GET_LINE_ITEM, SET_LOADING } from "@/core/services/store/visit.module";
import { ErrorEventBus } from "@/core/lib/message.lib";
import PMSchedule from "@/view/pages/preventive-maintanance/PMScheduleView.vue";
import RadioInputCustom from "@/view/components/app-component/RadioInputCustom.vue";

export default {
  name: "line-item-dialog-v2",
  props: {
    isSelfVisit: {
      type: Boolean,
      default: false,
    },
    duplicateId: {
      type: Number,
      default: 0,
    },

    productType: {
      type: String,
      default: null,
    },
    productDialog: {
      type: Boolean,
      default: false,
    },
    moduleType: {
      type: Number,
      default: 0,
    },
    isType: {
      type: Number,
      default: 0,
    },
    moduleTypeStr: {
      type: String,
      default: null,
    },
    rentalStartDate: {
      type: String,
      default: null,
    },
    rentalEndDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      search: null,
      category: "all",
      pmId: 0,
      scheduleDialog: false,
      alphabet: "all",
      eqp_filter: "all",
    };
  },
  components: {
    Dialog,
    PMSchedule,
    RadioInputCustom,
  },
  methods: {
    openSchedule(id) {
      this.pmId = id;
      this.scheduleDialog = true;
    },
    routeToDetailLink(id, type) {
      this.$router.push({
        name: type,
        params: { id },
      });
    },
    getDisabled(row) {
      if (
        this.moduleType &&
        this.moduleType == 7 &&
        row?.preventive_id > 0 &&
        row?.item_count > 0
      ) {
        return true;
      }
      return false;
    },
    find_items() {
      let productType = this.productType;
      if (productType == "product") {
        productType = "goods";
      }
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(GET_LINE_ITEM, {
          type: productType,
          search: this.search,
          category: this.category,
          alphabet: this.alphabet,
          customer: this.checkCustomerValidation(),
          module: this.moduleTypeStr ? this.moduleTypeStr : "",
          rental_start_date: this.rentalStartDate,
          rental_end_date: this.rentalEndDate,
          duplicate_id: this.duplicateId,
          is_self_visit: this.isSelfVisit,
          validated_date: this.getValidatedDate(),
          deliveryType:
            this.moduleTypeStr == "delivery" ? this.deliveryType : "",
        })
        .then(() => {})
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    getValidatedDate() {
      if (this.moduleTypeStr == "visit") {
        return this.oneOffSchedule?.start_date;
      } else if (this.moduleTypeStr == "delivery") {
        return this.deliverySchedule?.start_date;
      } else {
        ("");
      }
    },
    checkCustomerValidation() {
      if (
        this.moduleType == 10 &&
        this.deliveryType == "outgoing" &&
        this.productType == "equipment" &&
        this.eqp_filter == "all"
      ) {
        return 0;
      }
      /* if (
          this.moduleType == 10 &&
          this.deliveryType == "outgoing" &&
          this.productType == "equipment" && this.eqp_filter == 'all'
        ) {
          return 0;
        } */
      if (this.productType == "tools") {
        return 0;
      }
      return this.vCustomerId;
    },
    save_line_item() {
      let selected_line_items = [];

      let status = false;
      for (let item of this.vLineItem) {
        if (item.status) {
          status = true;
          if (!item.uom) {
            item.unit = "unit";
          }
          selected_line_items.push(item);
        }
      }
      let error = true;
      // console.log(this.isType, "pm");

      if (selected_line_items?.length > 0 && this.isType == 7) {
        for (let item of selected_line_items) {
          this.vSelectedLineItem.forEach((row) => {
            if (row.barcode == item.barcode) {
              ErrorEventBus.$emit(
                "update:error",
                `${item.barcode} already used `
              );
              error = false;
              return false;
            }
          });
        }
      }
      if (!error && this.isType == 7) {
        return false;
      }
      if (!status) {
        let typeFormated = this.productType;
        if (this.productType == "product") {
          typeFormated = "spare part";
        }
        ErrorEventBus.$emit("update:error", `Please select ${typeFormated}.`);
        return false;
      }
      this.$emit("update-values", selected_line_items);
      this.$emit("close", true);
    },
    update_all_line_item(status) {
      if (this.moduleType == 7) {
        for (let i = 0; i < this.vLineItem.length; i++) {
          if (
            /* this.vLineItem[i].preventive_id == 0 &&  */ this.vLineItem[i]
              .item_count == 0
          ) {
            this.vLineItem[i].status = status;
          }
        }
      } else {
        for (let i = 0; i < this.vLineItem.length; i++) {
          this.vLineItem[i].status = status;
        }
      }
    },
    filter_cat(param) {
      this.category = param;
      this.find_items();
    },
    filter_alpha(param) {
      this.alphabet = param;
      this.find_items();
    },
  },
  mounted() {
    if (this.productDialog) {
      this.find_items();
    }
  },
  computed: {
    ...mapGetters([
      "vCustomerId",
      "vLineItem",
      "vLineItemAlpha",
      "vStateLoading",
      "vLineItemCategory",
      "vSelectedLineItem",
      "deliveryType",
      "vCustomerType",
      "oneOffSchedule",
      "deliverySchedule",
      //"prventiveRecurringSchedule",
    ]),
    dialog_width() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
